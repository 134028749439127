<template>
    <div class="login-container">
      <div class="login-header"></div>
      <div class="card">
        <h1 class="title">{{$t('Welcome back')}}</h1>
          <el-form :model="form">
              <el-form-item>
                  <el-input :placeholder="($t('Input Username'))" class="input-box" v-model="username" auto-complete="off"></el-input>
              </el-form-item>
              <el-form-item>
                  <el-input 
                    :placeholder="($t('Password'))" 
                    class="input-box" 
                    v-model="password" 
                    type="password" 
                    auto-complete="off"
                    show-password></el-input>
              </el-form-item>
              <!-- <el-form-item>
                  <div class="captcha-box">
                    <el-input :placeholder="($t('captcha'))" class="captcha-input" v-model="inputCaptcha" auto-complete="off">
                    </el-input>
                    <img class="captcha-img" :src="captchaSrc" @click="queryCaptcha"/>
                  </div>
              </el-form-item> -->

              <el-form-item type="flex"   justify="space-between">
                <div class="space-flex-container">
                  <div class="forget-pass" @click="resetPassword">{{$t('Forget password?')}}</div>
                  <div class="forget-pass" @click="QRScan">{{$t('QR Scan')}}</div>
                </div>

              </el-form-item>
              <el-form-item>
                  <div class="login-button" type="primary" @click="LoginFunc">{{$t('Login')}}</div>
              </el-form-item>
              <el-form-item>
                <div class="signup-box">
                  <span class="text1">{{$t(`Don't have an account?`)}}</span>
                  <span class="text1 signup-btn" @click="registerFunc">{{$t('Sign up')}}</span>
                </div>
              </el-form-item>
          </el-form>
        </div>
      <div class="login-footer">
        <span class="policy-text">{{$t("Terms of use")}}</span>
        <span class="split-line"></span>
        <span class="policy-text">{{$t("Privacy policy")}}</span>
      </div>
      <div class="filing-info">
        <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2023014344号-1</a>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import loginApi from '../../api/loginApi';
  import { ElMessageBox } from 'element-plus'

  export default {
    data(){
      return {
        username: '',
        password: '',
        inputCaptcha: '',
        captchaSrc: '',
        captchaText: '',
        redirect_uri: 'https://liuyuncopilot.com/gpt/zh',
        client_id: 'sdf'
      }
    },
    async beforeMount(){
      // this.queryCaptcha()
      const code = this.$route.query.code
      const type = this.$route.query.type
      //说明是扫码跳转到此页面的
      if (code&&type){
        let param={
          code: code,
          type: type,
          response_type: 'code',
          redirect_uri: this.redirect_uri,
          client_id: this.client_id
        }
        //微信登录
        const res = await loginApi.getUserInfoByCode(param);
        console.log('getUserInfoByCode====',res.data)
        let  response = res.data;
        // eslint-disable-next-line no-debugger
        debugger
        console.log("response.code===8",response)
        if (res.code===8||response.code ===8 ){
          // eslint-disable-next-line no-debugger
          // debugger
          let thirdUUId= response.data.thirduuid;
          ElMessageBox.confirm(
              this.$t('Need Regiest') ,
              this.$t('Warm Tips') ,
              {
                confirmButtonText: this.$t('Go Band'),
                cancelButtonText: this.$t('Cancel'),
                type: 'warning',
              }
          )
              .then(() => {
                this.$router.push(`/BanAccount?type=${type}&thirduuid=${thirdUUId}`)
                /*ElMessage({
                  type: 'success',
                  message: 'Delete completed',
                })*/
              })
              .catch(() => {
                console.log("cancel")
              })

        }else if (res.code ===0&&response.code){
          window.location.href = `${this.redirect_uri}?code=${response.code}`
          return;
        }else {
          alert(response.msg)
        }
      }

      const client_id = this.$route.query.client_id
      const redirect_uri = this.$route.query.redirect_uri
      if(client_id!=undefined&&redirect_uri!=undefined){
        this.redirect_uri = redirect_uri
        this.client_id = client_id
      }
    },
    methods: {
      async queryCaptcha(){
        const res = await loginApi.queryCaptcha()
        this.captchaSrc = res.data.img
        this.captchaText = res.data.captchaText
      },
      async LoginFunc(){
        // if(this.inputCaptcha.toLocaleLowerCase() == this.captchaText.toLocaleLowerCase()
        //   &&this.inputCaptcha!=''){
          let param = {
            userName: this.username,
            password: this.password,
            response_type: 'code',
            redirect_uri: this.redirect_uri,
            client_id: this.client_id
          }
          const res = await loginApi.loginFunc(param)
          console.log('LoginFunc res = ',res);
          if(res.code == 0){
            window.location.href = `${this.redirect_uri}?code=${res.data}`
          }else{
            alert(res.msg)
          }
        // }else{
        //   alert('验证码错误')
        // }
      },
      async QRScan(){
        const res = await loginApi.qrScanRedireact()
        console.log(res)
        if (res.data&&res.data.url){
          window.location.href =res.data.url;
        }else {
          alert(res.data)
        }
      },
      registerFunc(){
        this.$router.push('/Register')
      },
      resetPassword(){
        this.$router.push('/ResetPassword')
      }
    },
    setup() {
      const form = ref({
        username: '',
        password: '',
      });
  
      const rules = {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      };
  
      function submitForm() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            // 这里可以添加相应的登录验证逻辑，比如调用接口验证用户名和密码是否正确
            console.log(`Username: ${form.value.username}, Password: ${form.value.password}`);
          } else {
            console.log('Validation failed');
            return false;
          }
        });
      }
  
      return { form, rules, submitForm };
    },
  };
  </script>
  
  <style scoped>

  .login-container {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    /* background-color: #f5f5f5; */
  }

  .login-header{
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    /* background-color: #19c37d; */
    text-align: center;
    line-height: 50px;
    font-weight: 900;
    margin-top: 32px;
    background: url('../assets/logo.png');
    background-size: 100% 100%;
  }
  .space-flex-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .card {
    /* width: 400px; */
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .title {
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;
  }

  .input-box{
    width: 320px;
    height: 52px;
    /* margin-top: 16px; */
  }

  /* .input-box /deep/ .el-input__inner{
    width: 320px;
    height: 52px;
    padding-left: 10px;
    border-width: 1px;
    border-color: gray;
    border-radius: 2px;
    box-sizing: border-box;
  } */
  /* .captcha-input /deep/ .el-input__inner{
    width: 320px;
    height: 52px;
    padding-left: 10px;
    border-width: 1px;
    border-color: gray;
    border-radius: 2px;
    box-sizing: border-box;
  } */

  .forget-pass{
    color: #19c37d;
    /* margin-top: 16px; */
    text-align: left;
    cursor: pointer;
  }

  .login-button{
    width: 322px;
    height: 52px;
    background-color: #19c37d;
    /* margin-top: 16px; */
    line-height: 52px;
    font-weight: 600;
    font-size: 20px;
    color: white;
    border-radius: 2px;
    cursor: pointer;
  }

  .signup-box{
    /* margin-top: 16px; */
  }

  .text1{
    font-weight: 400;
    font-size: 16px;
  }

  .signup-btn{
    font-weight: 600;
    color: #19c37d;
    cursor: pointer;
  }

  .login-footer{
    height: 30px;
    /* margin-bottom: 50px; */
    line-height: 30px;
    display: flex;
    align-items: center;
  }

  .policy-text{
    color: #19c37d;
    cursor: pointer;
  }

  .split-line{
    height: 20px;
    width: 1px;
    display: inline-block;
    background: black;
    margin: 0 10px;
  }

  .captcha-box{
    display: flex;
  }

  .captcha-input{
    width: 200px;
    /* margin-top: 16px; */
  }

  /* .captcha-input>>>.el-input__inner{
    width: 270px;
  } */

  .captcha-img{
    width: 120px;
    height: 50px;
    /* margin-top: 16px; */
    display: inline;
    object-fit: cover;
    padding: 1px;
    cursor: pointer;
  }
  .filing-info{
    height: 40px;
    flex-shrink: 0;
  }
  a {
    color: black;
    text-decoration: none;
  }
</style>
  